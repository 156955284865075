import { Badge, Calendar, Divider, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from "moment";
import 'moment-timezone';
import { getColor } from "../../util/CC";
import { apiCall } from "../../components/utilities/Api";
import { prettyTime } from "../../util/Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CalendarUI(props) {

    const template = window.sitesettings.template_info;
    const history = useHistory(); 
    const [init, setInit] = useState(true);

    const {
       
        // backwards compatable...
        data = {},
        injected_data = { rows : []}
    } = props;

    const {
        calendar_colour = 0,
        highlight_colour = 0,
        text_colour = 0,
        active_colour = 0,
        display_titles = false,
        calendar_header_colour = 0,
        weekdays_background_colour = 0,
        prev_next_background_colour = 0,
        day_border_colour = 0,
        date_bar_preset = 0,
        day_preset = 0,
        categories = [],
        display_categories = true,
    } = data; 

    const [results, setResults] = useState(injected_data);

    const [date, setDate] = useState(moment().tz('America/Los_Angeles'));

    function inRange(_date, _start, _end) {
        const i_date = moment(_date, 'YYYYMMDD');
        const s_date = moment(_start, 'YYYYMMDD');
        const e_date = moment(_end, 'YYYYMMDD');

        // Check if the i_date is within the range
        return i_date.isBetween(s_date, e_date, null, '[]');
    }

    const drawCategory = (_item) => {
        if(!_item.category){
            return(<><Tag style={{"padding" : "2px 6px", "lineHeight" : "12px", "whiteSpace" : "normal", "borderRadius" : "2px"}} color={"#000"}><span className="internal">Misc</span></Tag></>)
        }
        return(<Tag style={{"padding" : "2px 6px", "lineHeight" : "12px", "whiteSpace" : "normal", "borderRadius" : "2px"}} color={_item.category.colour}><span className="internal">{_item.category.name}</span></Tag>)
    }

    const drawBadge = (_item) => {
        if(!_item.category){
            return(<Badge color={"#000"} dot />)
        }
        return(<Badge color={_item.category.colour} dot />)
    
    }
   

    const fetchEvents = () => {
        
        if(init){
            setInit(false);
            return; 
        }

        let from = date.startOf("month").format('YYYYMMDD');
        let to = date.endOf("month").format('YYYYMMDD')

        let obj = {
            start_date: from,
            end_date: to,
            calendar_mode : true,
        }

        // NEW EVENTS
        if (window.sitesettings.using_events_v2) {

            if(categories){
                obj.categories = JSON.stringify([...categories].map(item => item.id));
            }

            apiCall("event/v2/list", obj, (_status, _result) => {
                if (_status) {
                    setResults(_result)
                } else {
                    console.log(_result.error)
                }
            })
        } else {
            apiCall("event/getList", { from: from, to: to, increment_views: false }, (_status, _result) => {
                if (_status) {
                    setResults(_result)
                }
            })
        }


    }
    useEffect(fetchEvents, [date]);

    const prev = () => {
        setDate(moment(date.subtract(1, "month")).tz('America/Los_Angeles'));
    }

    const next = () => {
        setDate(moment(date.add(1, "month")).tz('America/Los_Angeles'));
    }

    const isSameMonth = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2, 'month') && momentDate1.isSame(momentDate2, 'year');
    };

    const loadPopup = (_events = [], _date = moment()) => {


        if(_events.length === 1){
            // Just link out
            history.push("/events/" + _events[0].id); 
            return; 
        }

        let modal;

        const handleClose = () => {
          modal.destroy();
        };
        
        modal = Modal.info({
            icon : null,
            okText : "Close",
            title : <div>{_date.format("MMM Do YYYY")}<Divider dashed style={{"margin" : "15px 0px"}} /></div>,
            content : <>
                {_events.map(item => {
                    let { title = "", info = {}, id = ""} = item;
                    let { name = ""} = info; 
                    let _ti = (title) ? title : name; 
                return(<div>{drawBadge(item)}&nbsp;&nbsp;<a className="bm-lnk-1" onClick={() =>{ history.push("/events/" + id); handleClose()}} title={_ti}>{_ti}</a></div>)
            })}</>
        })
    }

    let inc = 0;
    const dateFullCellRender = (value) => {

        inc++;

        let border_right = (inc % 7);
        let border_bottom = (inc < 36);


        let _current_month = false;
        if (isSameMonth(date, value)) { _current_month = true; }

        // rows filter 
        let _dayEvents = results.rows.filter((item) => {
            return inRange(value.format("YYYYMMDD"), moment(item.from * 1000).tz('America/Los_Angeles').format("YYYYMMDD"), moment(item.to * 1000).tz('America/Los_Angeles').format("YYYYMMDD"))
        })

        // NEW EVENTS
        if (window.sitesettings.using_events_v2) {
            _dayEvents = results.rows.filter(item => {
                return (item.date.toString() === value.format("YYYYMMDD"));
            })
        }

        if (!_dayEvents.length || !_current_month) {

            let _bg = getColor(highlight_colour, template, "#fff");
            let _opacity = 1;
            if (!_current_month) {
                _opacity = 0.3;
            }
            if (!_current_month && prev_next_background_colour) {
                _bg = getColor(prev_next_background_colour, template, "#fff");
                _opacity = 1;
            }

            // NO EVENTS
            return (
                <div style={{ "borderBottom": (border_bottom) ? "1px solid " + getColor(day_border_colour, template, "#000") : "none", "borderRight": (border_right) ? "1px solid " + getColor(day_border_colour, template, "#000") : "none", "opacity": _opacity, "backgroundColor": _bg, "color": getColor(text_colour, template, "#000") }} className="eventCell">
                    <div style={{ "position": "absolute", "right": "5px", "top": "-2px" }}><small style={{ "color": getColor(text_colour, template, "#000") }} className="caldate"><span className={(day_preset) ? "bm-txt-" + day_preset : ""}><span style={{"fontSize" : "12px"}}>{value.format("D")}</span></span></small></div>
                </div>
            )
        } else {

            // HAS EVENTS DRAW DOTS
            return (
                <div onClick={() => loadPopup(_dayEvents, value)}  style={{ "borderBottom": (border_bottom) ? "1px solid " + getColor(day_border_colour, template, "#000") : "none", "borderRight": (border_right) ? "1px solid " + getColor(day_border_colour, template, "#000") : "none", "backgroundColor": getColor(active_colour, template, "#fff"), "cursor": (_current_month) ? "pointer" : "default", "overflow": "hidden", "justifyContent": "flex-start", "color": getColor(text_colour, template, "#fff") }} className="eventCellActive">
                    <div style={{ "position": "absolute", "right": "5px", "top": "-2px" }}><small style={{ "color": getColor(text_colour, template, "#000") }} className="caldate"><span className={(day_preset) ? "bm-txt-" + day_preset : ""}><span className={(day_preset) ? "bm-txt-" + day_preset : ""}>{value.format("D")}</span></span></small></div>
                    <div style={{ "textAlign": "left", "height": "100%" }} >
                        <div class={(display_titles || display_categories) ? "event-indicators-wrapper-resp" : "event-indicators-wrapper"}>

                            {((display_titles || display_categories) &&
                                <div class="event-indicators-resp">
                                    <ul style={{ "padding": "0px", "marginTop" : (display_categories) ? "12px" : "0px", "marginTop" : "10px" }}>
                                        {_dayEvents.slice(0, 3).map(item => {
                                            // New Events
                                            let _name = (window.sitesettings.using_events_v2) ? item.title : item.info.name;
                                            let _date = (window.sitesettings.using_events_v2) ? prettyTime(item.start_time) : moment(item.from * 1000).tz('America/Los_Angeles').format("h:mma");

                                            // Draw Categories
                                            if (display_categories) {
                                                return (
                                                    <li>
                                                        <small style={{ "fontSize": "12px", "color" : "#000" }}>
                                                            {drawCategory(item)}
                                                        </small>
                                                    </li>
                                                )
                                            }

                                            let _color = getColor(text_colour, template, "#000");
                                            if(item.category){
                                                _color = item.category.colour; 
                                            }

                                            // Draw Titles
                                            return (
                                                <li>
                                                    <div style={{ "display": "flex", "alignItems": "baseline", "lineHeight": "12px" }}>
                                                        <div style={{ "width": "11px" }}><div style={{ "width": "6px", "color": getColor(text_colour, template, "#000"), "backgroundColor": _color }} className="ant-badge-status-dot"></div></div>
                                                        <div style={{ "margin": "0px 2px", "color": getColor(text_colour, template, "#000") }} className="ant-badge-status-text">
                                                            <div><small><strong>{_date}</strong></small></div>
                                                            <div><small style={{ "fontSize": "12px" }}>
                                                                {_name}
                                                            </small></div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                            <div class={(display_titles || display_categories) ? "event-indicators-resp-small" : "event-indicators"}>
                                {_dayEvents.slice(0, 3).map((item) => {
                                    let _color = getColor(text_colour, template, "#000");
                                    if(item.category){
                                        _color = item.category.colour; 
                                    }
                                    return <Badge color={_color} />
                                
                                }
                                
                                )}
                            </div>
                        </div>
                    </div>
                </div>


            )

            return (
                <ul className="events">
                    {_dayEvents.slice(0, 3).map((item) => (
                        <li key={item.content}>
                            <pre>{JSON.stringify(item, null, 2)}</pre>
                            <Badge status={item.type} text={item.name} />
                        </li>
                    ))}
                </ul>
            )

            return (
                <div style={{ "backgroundColor": getColor(active_colour, template, "#fff"), "cursor": "pointer", "color": getColor(text_colour, template, "#fff") }} className="eventCellActive">
                    <small className="caldate">{value.format("D")}</small>
                    <div className="cluster">
                        {_dayEvents.slice(0, 3).map((dot, i) => {
                            return (<span key={i} style={{ "backgroundColor": getColor(text_colour, template, "#000") }} className="dot" />);
                        })}
                        {(_dayEvents.length > 3 && <small style={{ "color": getColor(text_colour, template, "#000") }}>+</small>)}
                    </div>
                </div>
            )
        }

    }

    const monthRender = () => {
        return (<></>)
    }

    const headerRender = (props) => {
        const { value } = props
        return (
            <>  
                <div style={{ "backgroundColor": getColor(calendar_header_colour, template, "#ffffff00"), "color": getColor(highlight_colour, template, "#fff") }} className="calHeader">
                    <div style={{ "alignItems": "center" }} className="float-flex">
                        <div className="cal_arrow" onClick={() => prev()} style={{ "padding": "10px 20px", "cursor": "pointer" }}><LeftOutlined /></div>
                        <div className={(date_bar_preset) ? "bm-txt-" + date_bar_preset : ""} style={{ "padding": "10px 20px" }}>{value.format("MMMM YYYY")}</div>
                        <div className="cal_arrow" onClick={() => next()} style={{ "padding": "10px 20px", "cursor": "pointer" }}><RightOutlined /></div>
                    </div>
                </div>
                <div className="float-flex" style={{ "justifyContent": "space-around", "padding": "5px 0px", "color": getColor(text_colour, template, "#000"), "backgroundColor": getColor(weekdays_background_colour, template, getColor(highlight_colour, template, "#fff")) }}>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>SU</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>MO</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>TU</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>WE</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>TH</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>FR</div>
                    <div className={(day_preset) ? "bm-txt-" + day_preset : ""}>SA</div>
                </div>
            </>
        )
    }

    return (
        <div style={{ "backgroundColor": getColor(calendar_colour, template, "#000") }} className="bm-cal" >
            <Calendar value={date} dateFullCellRender={dateFullCellRender} monthRender={monthRender} headerRender={headerRender} />
        </div>
    )
}

// import { Calendar, Button, Badge } from "antd";
// import React, { useEffect, useState } from "react";
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import moment from "moment";
// import 'moment-timezone';
// import { apiCall } from "../../components/utilities/Api";
// import { getColor } from "../../util/CC";
// import { useHistory } from "react-router-dom";

// export default function CalendarUI(props) {

//     const { data = [] } = props;
//     const history = useHistory(); 
//     const template = window.sitesettings.template_info;
//     const [results, setResults] = useState({ rows: data });
//     const [init, setInit] = useState(true);
//     const {
//         calendar_colour = 0,
//         highlight_colour = 0,
//         text_colour = 0,
//         active_colour = 0,
//         display_titles = false
//     } = props;

//     const [date, setDate] = useState(moment().tz('America/Los_Angeles'));

//     function inRange(_date, _start, _end) {

//         const i_date = moment(_date, 'YYYYMMDD');
//         const s_date = moment(_start, 'YYYYMMDD');
//         const e_date = moment(_end, 'YYYYMMDD');

//         // Check if the i_date is within the range
//         return i_date.isBetween(s_date, e_date, null, '[]');
//     }


//     const fetchEvents = () => {

//         if(init){
//             setInit(false);
//             return; 
//         }
//         let from = date.startOf("month").format('YYYYMMDD');
//         let to = date.endOf("month").format('YYYYMMDD')

//         apiCall("event/getList", { from: from, to: to, increment_views: false }, (_status, _result) => {
//             if (_status) {

//                 setResults(_result)
//             }
//         })
//     }

//     useEffect(fetchEvents, [date]);

   



//     const prev = () => {
//         setDate(moment(date.subtract(1, "month")).tz('America/Los_Angeles'));
//     }

//     const next = () => {
//         setDate(moment(date.add(1, "month")).tz('America/Los_Angeles'));
//     }


//     const dateFullCellRender = (value) => {

//         // rows filter 
//         let _dayEvents = results.rows.filter((item) => {
//             return inRange(value.format("YYYYMMDD"), moment(item.from * 1000).tz('America/Los_Angeles').format("YYYYMMDD"), moment(item.to * 1000).tz('America/Los_Angeles').format("YYYYMMDD"))
//         })

//         if (!_dayEvents.length) {
//             // NO EVENTS
//             return (
//                 <div style={{ "backgroundColor": getColor(highlight_colour, template, "#fff"), "color": getColor(text_colour, template, "#000") }} className="eventCell">
//                     <div style={{ "position": "absolute", "right": "2px", "top": "2px" }}><small style={{ "color": getColor(text_colour, template, "#000") }} className="caldate">{value.format("D")}</small></div>
//                 </div>
//             )
//         } else {
//             // HAS EVENTS DRAW DOTS

//             return (
//                 <div  onClick={() => history.push("/events/" + value.format("YYYYMMDD"))} style={{ "backgroundColor": getColor(active_colour, template, "#fff"), "cursor": "pointer", "overflow": "hidden", "justifyContent": "flex-start", "color": getColor(text_colour, template, "#fff") }} className="eventCellActive">
//                     <div style={{ "position": "absolute", "right": "2px", "top": "2px" }}><small style={{ "color": getColor(text_colour, template, "#000") }} className="caldate">{value.format("D")}</small></div>
//                     <div style={{ "textAlign": "left", "height": "100%" }} >
//                         <div class={(display_titles) ? "event-indicators-wrapper-resp" : "event-indicators-wrapper"}>
//                             {(display_titles &&
//                                 <div class="event-indicators-resp">
//                                     <ul style={{ "padding": "0px" }}>
//                                         {_dayEvents.slice(0, 3).map(item => {
//                                             return (
//                                                 <li>
//                                                     <div style={{ "display": "flex", "alignItems": "baseline", "lineHeight": "12px" }}>
//                                                         <div style={{ "width": "11px" }}><div style={{ "width": "6px", "color": getColor(text_colour, template, "#000"), "backgroundColor": getColor(text_colour, template, "#000") }} className="ant-badge-status-dot"></div></div>
//                                                         <div style={{ "margin": "0px 2px", "color": getColor(text_colour, template, "#000") }} className="ant-badge-status-text">
//                                                             <div><small><strong>{moment(item.from * 1000).format("h:mma")}</strong></small></div>
//                                                             <div><small style={{ "fontSize": "12px" }}>{item.info.name}</small></div>
//                                                         </div>
//                                                     </div>
//                                                 </li>
//                                             )
//                                         })}
//                                     </ul>
//                                 </div>
//                             )}
//                             <div class={(display_titles) ? "event-indicators-resp-small" : "event-indicators"}>
//                                 {_dayEvents.slice(0, 3).map((item) => (
//                                     <Badge color={getColor(text_colour, template, "#000")} />
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )

//             return (
//                 <div onClick={() => history.push("/events/" + value.format("YYYYMMDD"))} style={{ "backgroundColor": getColor(active_colour, template, "#fff"), "cursor": "pointer", "color": getColor(text_colour, template, "#fff") }} className="eventCellActive">
//                     <small style={{ "color": getColor(text_colour, template, "#000") }} className="caldate">{value.format("D")}</small>
//                     <div className="cluster">
//                         {_dayEvents.slice(0, 3).map((dot, i) => {
//                             return (<span key={i} style={{ "backgroundColor": getColor(text_colour, template, "#000") }} className="dot" />);
//                         })}
//                         {(_dayEvents.length > 3 && <small style={{ "color": getColor(text_colour, template, "#000") }}>+</small>)}
//                     </div>
//                 </div>
//             )
//         }

//     }

//     const monthRender = () => {
//         return (<></>)
//     }

//     const headerRender = (props) => {
//         const { value } = props
//         return (
//             <>
//                 <div style={{ "color": getColor(highlight_colour, template, "#fff") }} className="calHeader">
//                     <div className="float-flex">
//                         <div onClick={() => prev()} style={{ "padding": "10px 20px", "cursor": "pointer" }}><LeftOutlined /></div>
//                         <div style={{ "padding": "10px 20px" }}>{value.format("MMMM")}</div>
//                         <div onClick={() => next()} style={{ "padding": "10px 20px", "cursor": "pointer" }}><RightOutlined /></div>
//                     </div>
//                 </div>
//                 <div className="float-flex" style={{ "justifyContent": "space-around", "padding": "5px 0px", "color": getColor(text_colour, template, "#000"), "backgroundColor": getColor(highlight_colour, template, "#fff") }}>
//                     <div>SU</div>
//                     <div>MO</div>
//                     <div>TU</div>
//                     <div>WE</div>
//                     <div>TH</div>
//                     <div>FR</div>
//                     <div>SA</div>
//                 </div>
//             </>
//         )
//     }


//     return (
//         <div style={{ "backgroundColor": getColor(calendar_colour, template, "#000") }} className="bm-cal" >
//             <Calendar value={date} dateFullCellRender={dateFullCellRender} monthRender={monthRender} headerRender={headerRender} />
//         </div>
//     )
// }